@import 'variables';

//custom styles
@media screen and (max-width: 1600px) {
	.interactive_map_Container {
	    .map_container {
	        .map_overlay {
	        	max-height: none !important;

	            svg {
	            	height: unset !important;
	            }
	        }
	    }
	}
}

@media screen and (max-width: 1450px) {
	.interactive_map_Container {
	    .map_container {
	        .map_overlay {
	            svg {
	            	height: unset;
	            }
	        }
	    }
	}
}

@media screen and (max-width: 1439px) {
	.site-footer {
		height: unset;

	    .footer-inside {
	    	height: unset;
	    	padding-top: 20px;

	    	.footer-menu {
	    		width: 100%;
	    		padding-bottom: 22px;

	    		&:after {
	    			content: '';
	    			height: 2px;
	    			width: calc(100% - 20px);
	    			background: $darkgreen;
	    			position: absolute;
	    			bottom: 0px;
	    			left: 10px;
	    			right: 10px;
	    		}

	    		p {
	    			margin: 0 !important;
	    		}

	    		.main-footer {
	    			margin: 0;
	    			margin-top: 8px;
	    		}
	    	}
	        .footer-partner {
	        	width: 100%;
	        	padding-bottom: 35px;
	        	padding-top: 55px;

	        	.footer-headline {
	        		top: 21px;
	        	}

	        	.wpb_single_image {

	        		img {
	        			max-width: unset;
	        			width: calc(100% - 5px);
	        		}
	        	}
	        }
	    }
	}
}

@media screen and (max-width: 1365px) {
	.infobox_Container {

	    .infobox-inside {
	    	padding: 55px 0 45px 55px;

    		.img {
    			width: 100%;
    			padding-bottom: 100%;
    		}
	    }

	    .img_box {
	    	width: 100%;
	    }
	}
	.site-header {
	    .header-inside {

	        .main-navigation {
	            display: none;

	            &.mobile {
	            	display: block;
            	    width: calc(100% - 80px);
            	    padding: 0 40px;
            	    margin: 0;
            	    position: fixed;
            	    top: $headerHeight;
            	    background: $green;
            	    bottom: 0;
            	    left: 0;
            	    right: 0;
            	    z-index: -1;
            	    padding-top: 105px;

            	    ul {
            	    	width: 100%;
            	    	margin: 0;
            	    	padding: 0;

		                li {
		                	width: 100%;
		                	height: 60px;
		                	line-height: 60px;
		                	margin: 0;
		                	padding: 0;
		                	border-bottom: 1px solid white;

		                	&:last-of-type {
		                		border: 0;
		                		height: unset !important;
		                		line-height: unset !important;
		                	}

		                	&:nth-last-child(2) {
		                		border: 0;
		                	}
		                }

		                .current-menu-item {
		                    &:after {
		                    	content: none;
		                    }
		                }
		            }
	            }
	        }

	        .burger-menu {
	        	display: block;
			    float: right;
			    width: 24px;
			    height: 24px;
			    margin: 20.5px;
			    margin-right: 35px;
			    padding: 5px;
			    cursor: pointer;
			    background-image: url('./assets/img/vectors/burger-menu-open.svg');
			    background-size: 24px 21px;
			    background-repeat: no-repeat;
			    background-position: center;

			    &.open {
			    	background-image: url('./assets/img/vectors/burger-menu-close.svg');
			    }
	        }
	    }
	}
}


@media screen and (max-width: 1240px) {
	.home_bg_with_container {
		display: block;

		.home_bg_with_text {
			padding: 50px 40px 58px 40px !important;
		}

	    .home_bg_with_image {
	        display: none;
	    }
	}

	.highlight_Container {
	    .text-inside {
	    	width: 100%;
	    	padding: 85px;
	    }

	    .highlight-img {
	    	display: none;
	    }
	}

	.interactive_map_Container {
	    .schutzgebiete_container {
	        .swiper-schutzgebiete {
	            .swiper-wrapper {
	                .swiper-slide {
	                    .inside {
	                        h2 {
	                        	margin-bottom: 30px;
	                        	padding-bottom: 30px;
	                        	margin-top: 0;
	                        }

	                        p {
	                        	margin-bottom: 30px !important;
	                        }   
	                    }
	                }
	            }
	        }
	    }
	}
}

@media screen and (max-width: 1130px) {
	.interactive_map_Container {
	    .schutzgebiete_container {
	    	width: calc(100% - 50px);

	        .swiper-schutzgebiete {
	            .swiper-wrapper {
	                .swiper-slide {
	                    .inside {
	                    	h2 {
	                    		margin-bottom: 20px;
	                    		padding-bottom: 20px;
	                    	}

	                        p {
	                        	font-size: 16px;
	                        }   
	                    }
	                }
	            }

	            .swiper-button-prev {
	            	top: 0;
	            }

	            .swiper-button-next {
	            	bottom: 0;
	            }
	        }
	    }
	}

	._highlight_Container {
	    .text-inside {
	    	width: 100%;
	    	padding: 60px;

	        .headline {
	        	margin: 0;
	        }

	        p {
	        	margin-top: 10px;
	        }
	    }

	    .highlight-img {
	    	display: none;
	    }
	}
}

//tablet
@media screen and (max-width: 991px) {
	p {
		font-size: 16px;
		line-height: 23px;
	}

	h3 {
		font-size: 22px;
	}

	h6 {
		font-size: 19px;
	}

	.vc_btn3 {
		display: block;
		padding: 6px !important;
		border: 2px solid $green;
		width: 250px;
		margin: auto;
		text-decoration: none !important;
	}

	button.vc_btn3 {
	    padding: 10.5px !important;
		width: 250px;
		display: block;
		margin: auto;
		text-decoration: none !important;
	}

	.schutzgebiet_verlinker_container {
		.verlinker_card {
			.img {
				.text_container {
					width: 70.87349398%;

					h3 {
						font-size: 22px;
					}
				}
			}
		}
	}

	.site-content {

	    .content-inside {
	        padding: 0px 40px;
	        width: calc(100% - 80px);
	    }
	}

	.interactive_map_Container {
		.vc_row {
	        .vc_col-sm-12 {
	            &:first-of-type,
	            &:last-of-type {
	                width: 100%;
	            }
	        }
	    }

	    .map_container {
	    	background-size: contain;
	    	background-position: center;

	    	.map_overlay {
    			svg {
    				width: 100%;
    				height: calc(100vh - 75px);
    			}
    		}
	    }

	    .schutzgebiete_container {
	    	width: calc(100% - 40px);
	    	height: 500px;
	    	position: relative;
	    	overflow: hidden;
	    	margin: auto;
	    }
	}

	.expandable-row {
		width: 100% !important;

		.headingheadline {
			font-size: 40px;
		}
	}

	.post-header-container {
		margin-top: 30px;
	    .categories {
	        a {
	        }
	    }

	    h1 {
	    	font-size: 51px;
	    	margin: 0;
	    }
	}

	.wall-outer {
		margin-bottom: 40px !important;
	}

	.site-header {
		.breadcrumbContainer {
	    	.breadcrumbs {
	    		padding: 0 40px;
	    	}
	    }
	}

	.highlight_Container {
	    .text-inside {
	    	padding: 40px;
	    }
	}

	.home_news_card_Container {
		margin-bottom: 50px;

		.home_news_card {
			.img {
				.text_container {
					width: 70.87349398%;
					//0.7086567164

					.headline {
						font-size: 22px;
					}
				}
			}
		}
	}

	.magazin_container {
		margin-bottom: 50px;

	    .container-inside {
	    	.magazin_card {
	    		margin-bottom: 32px;
	    	}
	    }
	}

	.contentheader {
		margin-bottom: 50px;

	    .ch-inside {
	    	padding: 0;
	        width: 100%;

	        .ch-top {
	        	background-size: 45.42815675%;
	        	//0.5638297872

	            .ch-title, .ch-title p {
	            	font-size: 7vw;
	            	line-height: 8vw;
	            }

	            .ch-top-underline {
	            	width: 41.277778%;
	            }
	        }

	        .ch-middle {
	        	p {
	        		margin-bottom: 0 !important;
	        	}
	        }

	        .ch-middle.withoutstyle {
	        	margin-top: 40px;

	        	p {
	        		font-size: 19px;
	        		margin-bottom: 0px !important;
	        	}
	        }

	        .ch-bottom {
	        	margin-top: 26px;
	        }
	    }    
	}

	.home_bg_with_container {
		margin-bottom: 50px;

		.home_bg_with_text {
			padding: 45px 16.25544267% !important;
			padding-bottom: 50px !important;

			h3 {
				font-size: 30px;
			}
		}
	}

	.videoplayer-container {
		padding-bottom: 66.32653061%;
		margin-bottom: 50px;

	    .videoplayer-posterimage {
	        .videoplayer-playbutton {
	        	height: 68px;
	        	width: 68px;
	        }

	        .videoplayer-youtubelogo {
	        	right: -20px;
	        	height: 37px;
	        	width: 77px;
	        	bottom: -9px;
	        }
	    }
	}

	.site-footer {
		.footer-inside {
			padding: 0px;
		    width: calc(100% - 80px);
		    margin: auto;
		    padding-top: 36px;
		}
	}

	.readon_Container {
		width: 100% !important;
	}

	._highlight_Container {
	    .text-inside {
	    	padding: 40px;
	    }
	}
}

//mobile
@media screen and (max-width: 768px) {
	.site-content {
	    .content-inside {
	        padding: 0px 20px;
	        width: calc(100% - 40px);
	    }
	}

	#loginform {
		width: calc(100% - 80px);

	    .login-username,
	    .login-password {
	        label {
	        }

	        input {
	        }
	    }

	    .login-submit {

	        input {
	        }
	    }
	}

	.schutzgueter_loadon_container {
	    .text_area {
	        h3 {
	        }
	    }
	    .schutzgut_card {
	        .img {
	        }
	        .card-body {
	        	padding-top: 5px;

	            .headline {
	                h4 {
	                	line-height: 25px;

	                    span {
	                    }
	                }
	            }

	            .text {
	            	margin-top: 5px;

	            	p {
	            		margin-bottom: 40px !important;
	            	}
	            }
	        }
	    }
	    .append_loadon_container {
	        .btnLoadOn {
	        }

	    }
	}

	.slider_container {
		width: 100vw;
		margin-left: -20px;

		.swiper-schutzgut {
			.swiper-wrapper {
				.swiper-slide {
					.caption {
					    width: calc(100% - 40px);
    					margin: auto;
    					margin-top: 5px;
					}
				}
			}
		}
	}

	.schutzgebiet_verlinker_container {
		margin-bottom: 0;

		.verlinker_card {
			.img {
				.text_container {
					width: 70.86567164%;

					h3 {
						font-size: 18px;
					}
				}
			}
		}
	}

	.readon_Container {
		padding-bottom: 40px;
	}

	._highlight_Container {
		width: 100vw;
		margin-left: -20px;
	    .text-inside {
	    	padding: 40px 20px;
	    }
	}

	.interactive_map_Container {
		margin-bottom: 40px;
		width: calc(100% + 40px);
		margin-left: -20px;
		margin-right: -20px;
		overflow: hidden;

		.schutzgebiete_container {
			.swiper-schutzgebiete {
				.swiper-wrapper {
					.swiper-slide {
						.inside {
							h2 {
								font-size: 28px;
                            }

							p {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}

	.infobox_Container {
	    .infobox-inside {
	    	padding: 40px 20px;

	    	.infowarning_container {
	    		margin-bottom: 35px;
	    		
	    		.warning_img {
	    			margin-bottom: 10px;
	    		}

	    		.text_container {
	    			width: 100%;
	    		}
	    	}
	    }
	}

	.highlight_Container {		
    	width: 100vw;
    	margin-left: -10px;

	    .text-inside {
	    	padding: 40px 20px;

	    	.categories {
	    		margin: 0;
	    		margin-bottom: 10px;
	    	}

	    	.headline {
	    		margin: 0;
	    		margin-bottom: 7px;
	    	}

	    	p {
	    		margin: 0;
	    	}
	    }

	    .highlight-img {
	    	display: none;
	    }
	}

	.glossary_container {
		.glossary_filters {
			margin-bottom: 40px;
		}

	    .glossary_content {
	        .glossary_card {
	        	margin-bottom: 30px;

	            .card-header {
	                .img {
	                }

	                i {
	                }
	            }

	            .card-body {

	                .categories {
	                	margin-top: 7px;

	                    a {
	                    	font-size: 14px;
	                    }
	                }

	                .headline {
	                	margin-top: 5px;

	                    .latinname {
	                    }

	                    h4 {
	                    	font-size: 22px;
	                    }
	                }



	                .headline_link {
	                	margin-top: 1px;
	                	margin-bottom: 7px;

	                    a {

	                        h3 {
	                    		font-size: 22px;
	                        }
	                    }
	                    
	                }

	                .text {

	                    p {
	                    	margin-bottom: 0 !important;
	                    }
	                }
	            }
	        }
	    }
	}

	.downloadmanager_Container_all {
		._inside {
			.download {
				i {
					display: block;
					float: left;
				}

				a {
					display: block;
					padding-top: 8px;
					padding-bottom: 10px;
				}
			}
		}
	}

	.downloadmanager_Container_single {
		i {
			display: block;
			float: left;
		}

		a {
			display: block;
			padding-top: 8px;
			padding-bottom: 10px;
		}
	}

	.magazin_container {
		padding: 0px;
		margin: 0px;

		.container-inside {
			.magazin_card {
				margin-bottom: 40px;

				.card-inside {
					.card_body {
						.categories {
							margin-top: 7px;

							a {
								font-size: 14px;
							}
						}

						.headline {
							margin-top: 0px;
						}

						p {
							margin-top: 7px;
						}
					}
				}
			}
		}

		.vc_btn3 {
			display: none;
		}
	}

	.home_news_card_Container {
		margin-bottom: 40px;

		.home_news_card {
			.img {
				.text_container {
					width: 70.86567164%;
				}
			}
		}
	}

	.iframe-container {
	    .calendarEvent {
	        .calendarListLink {
	            .calendarEventData {
	            	width: calc(100% - 20px) !important;
	            	padding: 5px 0 !important;
	            	margin-left: 20px !important;
	            }
	            .calendarEventContent {
	            	width: calc(100% - 20px) !important;
	            	margin-left: 20px !important;
	            }
	        }
	    }
	}

	.contentheader {
    	margin-bottom: 40px;

	    .ch-inside {
	    	width: 100%;
	    	padding: 0;
	    	padding-bottom: 1px;

	        .ch-top {
	        	background-size: contain;
	        	padding-top: 0;
	        	padding-bottom: 0;
    			margin-top: 27px;

	            span, span p {
	            	font-size: 38px;
	            	line-height: 48px;
	            }

	            .ch-top-underline {
	            	width: 154.094px;
	            	bottom: -20px;
	            }
	        }

	        .ch-bottom {
	        	margin-bottom: 0;
	        }
	    }    
	}	        

	.site-header {

	    .header-inside {
	    	height: $mobile-headerHeight !important;

	    	.site-logo {
	        	background-image: url('./assets/img/vectors/avn_logo_mobile.svg');
	        	width: 230px;
	        	height: 60px;
	        }

	        .main-navigation {
	            display: none;

	            &.mobile {
            	    top: $mobile-headerHeight;
            	    width: calc(100% - 40px);
            	    padding: 0 20px;
            	    padding-top: 5px;
            	}
            }

            .burger-menu {
            	margin: 13px;
            	margin-right: 28px;
	        }
	    }
	    .header-image-container {
	        margin-top: $mobile-headerHeight;
	    }

	    .breadcrumbContainer {

	    	.breadcrumbs {
	    		padding: 0 20px;
	    	}

	        &.space-top {
	            margin-top: $mobile-headerHeight;
	        }
	    }
	}

	.home_bg_with_container {
		margin-left: -20px;
		margin-bottom: 40px;
		width: 100vw;

		.home_bg_with_text {
			padding: 40px 20px 50px 20px !important;

			h3 {
				font-size: 26px;
				margin-bottom: 13px !important;
			}

			p {
				font-size: 16px;
				margin-bottom: 38px !important;
				margin-top: 0 !important;
			}
		}
	}

	.videoplayer-container {
		margin-bottom: 40px;

	    .videoplayer-posterimage {
	        .videoplayer-playbutton {
	        	height: 39px;
	        	width: 39px;
	        }

	        .videoplayer-youtubelogo {
	        	height: 21px;
	        	width: 42px;
	        	right: -10px;
	        	bottom: -8px;
	        }
	    }
	}

	.site-footer {
	    .footer-inside {
	        .footer-partner {
	        	.partner-logos {

	        		.wpb_single_image {
	        			width: calc(33.33% - 7px);
	        			margin: 10px 5.2px;

	        			img {
	        				max-width: 100%;
        				    width: auto;
        				    text-align: center;
        				    margin: auto;
        				    left: 0;
        				    right: 0;
	        			}

	        			&:nth-child(-n + 3) {
	        				margin-top: 0px;
	        			}

	        			&:nth-child(3n) {
	        				margin-right: 0px;
	        			}

	        			&:nth-child(3n+1) {
	        				margin-left: 0px;
	        			}
	        		}
	        	}
	        }
	    }
	}

	.site-footer {
		.footer-inside {
			padding: 0px;
		    width: calc(100% - 40px);
		    margin: auto;
		    padding-top: 36px;
		}
	}
	.vc_tta-container {
	   
	    .vc_tta-panel {
	    	.vc_tta-panel-heading {
	     	    h4 {
	    	        a {
	    	            .vc_tta-title-text {
	    	                font-size: 18px;
	    	            
	    	            }
	    	            .vc_tta-controls-icon.vc_tta-controls-icon-pls {
	    	            	font-size: 18px;
	    	            }

	    	        }
	    	    }
	    	}
	   

	        .vc_tta-panel-body {
	            padding: 10px 0px !important;
	            background-color: transparent !important;
	            border: 0 !important;

	            table {
	                width: 100%;
	                border-collapse: collapse;
	                border-spacing: 0;
	                

	                tbody {
	                
	                	width: 100%;
	                }

	                tr {
	                    height: auto;
	             
	                    width:100%;
	                    &:nth-child(odd) {
	                    	 td {
	                  			background-color: $white;
	               		 	}

	                    }
	           
	                    td {
	                    	display:block;
	                    	width:100%;
	                    	float: left;
	                    	text-align: left;
	                    }

	                    td:first-of-type {
	                        padding-left: 0;
	                        
	                    }

	                    td:last-of-type {
	                        padding-right:0;
	                    }
	                    
	                }

	                tr:nth-child(odd) {
	                    background-color: $white;
	                }

	                tr, tr a {
	                    font-size: 18px !important;
	                }
	            }
	        }
	    }
	}
}

@media screen and (max-width: 767px) {
	.site-header {
		&.space-bottom-sm {
			padding-bottom: $mobile-headerHeight;
		}
	}

	.infobox_Container {
	    .img_box {
	    	padding-bottom: 128.62068966%;

	        .img {
	        	width: 100%;
	        }
	    }
	}

	.contentheader {
	    .ch-inside {
	        .ch-top {

	            span, span p {
	            	font-size: 32px;
	            }
	        }
	    }    
	}	    
}

@media screen and (max-width: 450px) {
	.iframe-container {
		.calendarEventDate {
			width: 45px !important;
			height: 45px !important;
			padding-top: 25px !important;

	        .calendarEventDateMonth {
	        	margin: -6px 0 0 11px !important
	        }

	        .calendarEventDateDay {
	        	margin: 2px 0 0 10px !important;
	        }
	    }
	    .calendarEvent {
	        .calendarListLink {
	            .calendarEventData {
	            	width: calc(100% - 5px) !important;
	            	margin-left: 5px !important;
	            }
	            .calendarEventContent {
	            	width: calc(100% - 5px) !important;
	            	margin-left: 5px !important;
	            }
	        }
	    }
	}

	.interactive_map_Container {
		padding-bottom: 40px;

		.map_container {
			margin-top: 40px;

			.map_overlay {
				height: calc(70vh - 75px);

				svg {
					height: calc(70vh - 75px);
				}
			}
		}
	}
}

@media screen and (max-width: 310px) {
	.vc_btn3 {
		width: calc(100% - 20px);
	}
}