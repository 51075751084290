$darkgreen: #377B20;
$green: #AAC80C;
$lightgreen: #EEF4CE;
$white: #F6F8F3;
$black: #000000;

$fullLayout: 1280px;
$mobileLayout: 767px;
$contentWidth: 848px;

$sm-content-wth: 58.88888889%;

$headerHeight: 75px;
$mobile-headerHeight: 60px;
$footerHeight: 168px;